import {
  Component,
  Input,
  Optional,
  inject,
  OnInit,
  OnChanges,
} from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { take } from 'rxjs/operators';

import {
  FiscalPrinter,
  FiscalPrinterCheckData,
  FiscalPrinterCheckMethodsType,
} from '../../models';
import {
  FiscalPrinterCheckResult,
  FiscalPrinterChecksService,
} from '../../use-cases/fiscal-printers/fiscal-printer-checks.service';
import { TypedSimpleChanges } from '../../models/types/simple-changes-typed';

@Component({
  selector: 'by-fiscal-printer-check',
  templateUrl: './fiscal-printer-check.component.html',
  styleUrls: ['./fiscal-printer-check.component.scss'],
})
export class FiscalPrinterCheckComponent implements OnInit, OnChanges {
  @Input() checkData: FiscalPrinterCheckData;

  @Input() showLoading = false;

  @Input() checkMethodsToSkip: FiscalPrinterCheckMethodsType[] = [];

  @Input() warnings: string[];

  selectedPrinter: FiscalPrinter;

  checkResults: FiscalPrinterCheckResult;

  checkResultsLoading = false;

  readonly nzModalData = inject(NZ_MODAL_DATA);

  constructor(
    private fiscalPrinterChecksService: FiscalPrinterChecksService,
    @Optional() private modalRef: NzModalRef,
  ) {}

  ngOnChanges(
    changes: TypedSimpleChanges<{ checkData: FiscalPrinterCheckData }>,
  ): void {
    const { checkData } = changes;

    if (checkData && this.checkData) {
      this.setCheckData();
    }
  }

  ngOnInit(): void {
    if (!this.nzModalData) {
      return;
    }
    const { checkMethodsToSkip, checkData, showLoading, warnings } =
      this.nzModalData;

    this.selectedPrinter = checkData.selectedPrinter;
    this.showLoading = showLoading;
    this.warnings = warnings;
    this.checkMethodsToSkip = checkMethodsToSkip;

    this.onCheckStatus();
  }

  setCheckData() {
    this.checkMethodsToSkip = this.checkData?.checkMethodsToSkip || [];
    this.selectedPrinter = this.checkData?.selectedPrinter;
    if (!this.selectedPrinter) {
      return;
    }
    this.onCheckStatus();
  }

  onCheckStatus() {
    this.checkResultsLoading = true;
    this.fiscalPrinterChecksService
      .check(this.selectedPrinter, this.checkMethodsToSkip)
      .pipe(take(1))
      .subscribe((results) => {
        this.checkResults = results;
        this.checkResultsLoading = false;
      });
  }

  get modalInstance() {
    return this.modalRef;
  }
}
