import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { first } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { selectAllPropertiesNames } from '../../core/+state/core.reducer';
import { removeNullishValues } from '../../helpers';
import {
  FiscalPrinter,
  FiscalPrinterCheckData,
  InvoiceDetails,
} from '../../models';
import { RootState } from '../../root-store/root-state';
import { FiscalPrinterCheckResult } from '../../use-cases/fiscal-printers/fiscal-printer-checks.service';
import { FiscalPrinterCheckComponent } from '../fiscal-printer-check/fiscal-printer-check.component';
import { FiscalPrinterDriver } from '../../use-cases/fiscal-printers/fiscal-printer-driver';
import { FiscalPrinterResolverService } from '../../use-cases/fiscal-printers/fiscal-printer-resolver.service';

@Component({
  selector: 'by-print-receipt-modal',
  templateUrl: './print-receipt-modal.component.html',
  styleUrls: ['./print-receipt-modal.component.scss'],
})
export class PrintReceiptModalComponent implements OnInit, OnDestroy {
  readonly nzModalData: PrintReceiptModalComponent = inject(NZ_MODAL_DATA);

  @Input()
  fiscalPrinters: FiscalPrinter[] = this.nzModalData?.fiscalPrinters;

  @Input()
  propertiesIds: number[] = this.nzModalData?.propertiesIds;

  @Input()
  invoice: InvoiceDetails = this.nzModalData?.invoice;

  propertiesNames$ = this.store.pipe(select(selectAllPropertiesNames));

  selectedPrinter: FiscalPrinter;

  selectedPrinterDriver: FiscalPrinterDriver;

  fiscalPrinterCheckData: FiscalPrinterCheckData;

  isReversalReceipt = false;

  checkResults: FiscalPrinterCheckResult;

  usePrintedReceipt = false;

  form = this.formBuilder.group({
    property_id: [null],
    printer_id: [null],
    clousure_number: [null],
    invoice_date: [new Date()],
    number: [null],
  });

  @ViewChild(FiscalPrinterCheckComponent)
  fiscalPrinterCheckComponent: FiscalPrinterCheckComponent;

  private subs = new SubSink();

  constructor(
    private modalRef: NzModalRef,
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private fiscalPrinterResolverService: FiscalPrinterResolverService,
  ) {}

  get submitIsDisabled(): boolean {
    if (this.usePrintedReceipt) {
      return this.form.invalid;
    }

    return (
      this.form.invalid ||
      !this.fiscalPrinterCheckComponent?.checkResults?.checkPassed
    );
  }

  ngOnInit() {
    this.subs.add(
      this.form
        .get('printer_id')
        .valueChanges.subscribe((printerId) => this.onPrinterChange(printerId)),
    );

    if (!this.fiscalPrinters?.length) {
      return;
    }

    if (this.propertiesIds) {
      this.form.patchValue({
        property_id: this.propertiesIds[0],
      });
    }

    const { clousure_number, printer_id, printing_sessions } = this.invoice;

    this.form.patchValue({
      clousure_number,
      printer_id:
        printer_id || this.fiscalPrinters?.length === 1
          ? this.fiscalPrinters[0].id
          : null,
    });

    this.usePrintedReceipt = first(printing_sessions)?.status === 'PENDING';

    this.isReversalReceipt = !!printer_id;

    if (this.isReversalReceipt) {
      const clousureNumberControl = this.form.get('clousure_number');
      clousureNumberControl.setValidators([Validators.required]);
      clousureNumberControl.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onPrinterChange(fiscalPrinterId: number) {
    this.selectedPrinter = this.fiscalPrinters?.find(
      ({ id }) => id === fiscalPrinterId,
    );

    this.fiscalPrinterCheckData = { selectedPrinter: this.selectedPrinter };

    this.selectedPrinterDriver = this.fiscalPrinterResolverService.getInstance(
      this.selectedPrinter?.device_model_id,
    );
  }

  onUsePrintedReceiptChange() {
    const {
      clousure_number: clousureNumberControl,
      invoice_date: invoiceDateControl,
      number: numberControl,
    } = this.form.controls;

    clousureNumberControl.clearValidators();
    invoiceDateControl.clearValidators();
    numberControl.clearValidators();

    if (this.usePrintedReceipt) {
      invoiceDateControl.setValidators([Validators.required]);
      numberControl.setValidators([Validators.required]);
    }

    if (this.usePrintedReceipt || this.isReversalReceipt) {
      clousureNumberControl.setValidators([Validators.required]);
    }

    if (this.usePrintedReceipt && this.isReversalReceipt) {
      clousureNumberControl.setValue(null);
    }

    if (!this.usePrintedReceipt && this.isReversalReceipt) {
      clousureNumberControl.setValue(this.invoice?.clousure_number);
    }

    clousureNumberControl.updateValueAndValidity();
    invoiceDateControl.updateValueAndValidity();
    numberControl.updateValueAndValidity();
  }

  onCloseModal() {
    this.modalRef.close();
  }

  onSubmit() {
    this.modalRef.close(
      removeNullishValues({
        printer: this.selectedPrinter,
        ...this.form.value,
        manual_fp_register: this.usePrintedReceipt ? 1 : null,
      }),
    );
  }

  get checkResultsLoading(): boolean {
    return this.fiscalPrinterCheckComponent?.checkResultsLoading;
  }

  get showPrintingSessionsMessage() {
    return (
      first(this.invoice?.printing_sessions)?.status === 'PENDING' &&
      !this.invoice?.registered
    );
  }
}
